import React from 'react';
import './SomeExperience.css';
import { TiDownload } from "react-icons/ti";

const SomeExperience = () => {
  const experiences = [
    {
      title: "Vice President & IT Department Member",
      organization: "Poli.Radio",
      period: "04/2023 - Present",
      highlights: [
        "Developed and maintained internal services for a 150-member organization, implementing technical solutions to streamline operations",
        "Led strategic budget management and resource allocation across departments, demonstrating project management and leadership skills",
        "Coordinated technical setups for large-scale events (500+ attendees), ensuring seamless audio/visual operations",
        "Implemented automated broadcast scheduling systems and managed audio production workflows using industry-standard software"
      ]
    },
    {
      title: "Computer Science and Engineering",
      organization: "Polytechnic University of Milan",
      period: "09/2022 - Present",
      highlights: [
        "Master's degree candidate maintaining a GPA of 28.4/30",
        "Specializing in Artificial Intelligence and Machine Learning",
        "Selected projects and research work can be found in my portfolio section"
      ]
    },
    {
      title: "Computer Engineering",
      organization: "University of Bergamo",
      period: "08/2019 - 07/2022",
      highlights: [
        "Graduated with honors (105/110)",
        "Awarded Top 10% scholarship for academic excellence",
        "Developed strong fundamentals in computer science and engineering principles"
      ]
    }
  ];

  return (
    <div className="experience-bg">
      <header className="experience-header">
      <h2 className="experience-title" style={{ borderBottom: 'none' }}>
        Some of my
        <span className="title-orange"> experiences</span>
      </h2>
      <p className="experience-description">
        My journey combines academic excellence with practical experience in technology and leadership, shaping my path in software engineering and AI development.
      </p>
      </header>
      
      <div className="experience-container">
      {experiences.map((exp, index) => (
        <div key={index} className="experience-item">
        <div className="experience-item-header">
          <div>
          <h3 className="experience-item-title">{exp.title}</h3>
          <p className="experience-item-organization">{exp.organization}</p>
          </div>
          <span className="experience-item-period">{exp.period}</span>
        </div>
        <ul className="experience-highlights">
          {exp.highlights.map((highlight, i) => (
          <li key={i} className="experience-highlight">
            <span className="highlight-icon">›</span>
            {highlight}
          </li>
          ))}
        </ul>
        </div>
      ))}
      </div>
      <div className="button-container">
        <a className="button" href="/docs/resume.pdf" download>
          Download Resume<br />
          <TiDownload className="arrow-icon" />
        </a>
      </div>
    </div>
  );
};

export default SomeExperience;
