import React from 'react';
import './About.css'; 

const About = () => {
    return (
        <div className="about-container">
            <header className="about-header">
                <p>About</p>
                <h1>Who am I</h1>
            </header>
            <section className="about-content">
                <div className="about-item">
                    <p>
                    I am Luca, a student in the master's degree program in Computer Science and Engineering at Politecnico di Milano.
                    My journey into technology began with a natural talent for mathematics and problem-solving, which evolved 
                    into a deep fascination with engineering. Through my academic journey, I discovered my true calling in 
                    computer science, particularly in the field of AI, which became my minor specialization.
                    I am a very ambitious and determined person who loves to face new challenges. I enjoy discovering 
                    new things and immersing myself in different experiences that spur my curiosity. This drive for knowledge 
                    pushes me to constantly explore emerging technologies and become deeply invested in the topics I study.
                    </p>
                </div>
            </section>
        </div>
    );
};

export default About;
