import React from 'react';
import './Main.css';
import { TiArrowDownThick } from "react-icons/ti";

const Main = () => {

    const handleScrollToAbout = () => {
        const aboutSection = document.getElementById('about');
        if (aboutSection) {
            aboutSection.scrollIntoView({ behavior: 'smooth' });
        }
    };

    return (
        <>
            <main className="main-content">
                <div className='writings'>
                    <div className='myname'>
                        <div className='myname1'>I am </div>
                        <div className='space'> </div>
                        <div className='myname2'>Luca</div>
                        <div className='myname1'>,</div>
                    </div>
                    <div className='myname'>
                        <div className='myname3'>Software</div>
                        <div className='space'></div>
                        <div className='space'></div>
                        <div className='space'></div>
                        <div className='myname3'>Engineer</div>
                    </div>
                </div>
                <div className="image-container">
                    <img src={"/no_background_with_dot.png"} alt="Luca Brembilla" className="profile-image-dot"/>
                </div>
                <div className='citation'>
                    The good news about computers is that they do what you tell them to do. 
                    <br></br>
                    The bad news is that they do what you tell them to do.
                </div>
                <div className="scroll-button-container">
                    <button className="scroll-button" onClick={handleScrollToAbout}>
                        Click to know more<br></br>
                        <TiArrowDownThick className="arrow-icon" />
                    </button>
                </div>
            </main>
        </>
    );
};

export default Main;
