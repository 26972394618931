import React, { useEffect } from 'react';
import Main from './main/Main';
import About from './about/About';
import SomeExperience from './about/SomeExperience';
import ComputerScience from './computer_science/ComputerScience';
import Contact from './contact/Contact';

const HomePage = ({ activeSection, setActiveSection }) => {
  useEffect(() => {
    const handleScroll = () => {
      const sections = document.querySelectorAll('section');
      let maxVisibleHeight = 0;
      let currentSection = activeSection;
  
      sections.forEach((section) => {
        const rect = section.getBoundingClientRect();
        const visibleHeight = Math.min(rect.bottom, window.innerHeight) - Math.max(rect.top, 0);
  
        if (visibleHeight > maxVisibleHeight) {
          maxVisibleHeight = visibleHeight;
          currentSection = section.id;
        }
      });
  
      if (currentSection !== activeSection) {
        setActiveSection(currentSection);
	// window.history.replaceState(null, '', `#${currentSection}`);
      }
    };
  
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, [activeSection, setActiveSection]);

  return (
    <main>
      <section id="home">
        <Main />
      </section>
      <section id="about">
        <About />
        <SomeExperience />
      </section>
      <section id="computerscience">
        <ComputerScience />
      </section>
      <section id="contact">
        <Contact />
      </section>
    </main>
  );
};

export default HomePage;

