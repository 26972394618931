import { React, useEffect } from 'react';
import './PlantClassification.css';

const PlantClassification = () => {

    useEffect(() => {
      window.scrollTo(0, 0);
    }, []);

    return (
        <div className="project-details">
            <h1>Plant Disease by Image Classification</h1>
            <img src={"/PlantDisease2.png"} className="project-image" />
            <section>
                <h2>Project Overview</h2>
                <p><strong>Objective: </strong>
                  Develop a neural network model to classify images of plants as either diseased or healthy. This is a binary classification problem where the model must decide between two possible categories.
                </p>
                <p><strong>Approach: </strong>
                  Used transfer learning with ConvNeXtBase, a pre-trained convolutional neural network. Transfer learning helps improve performance and speeds up training by leveraging pre-existing knowledge from a large dataset.
                </p>
            </section>
            <section>
                <h2>Model Architecture</h2>
                <h3>Base Model</h3>
                <p><strong>Name: </strong> 
                  ConvNeXtBase
                </p>
                <p><strong>Description: </strong>
                A convolutional neural network pre-trained on ImageNet. The first 277 layers are frozen, and the remaining layers are fine-tuned for plant disease classification.
                </p>
                <h3>Dense Layers</h3>
                <p>
                Additional fully connected layers that process the output from the base model.
                </p>
                <ul>
                    <li>Leaky ReLU activation functions to improve learning speed.</li>
                    <li>Dropout to prevent overfitting by randomly ignoring some neurons during training.</li>
                    <li>Batch Normalization to stabilize and speed up training.</li>
                    <li>Ridge Regularization to control model complexity and prevent overfitting.</li>
                </ul>
            </section>
            <section>
                <h2>Training and Tuning</h2>
                <p><strong>Dataset: </strong>
                  5,004 images split into training (80%) and validation (20%) sets.
                </p>
                <p><strong>Description: </strong> 
                Adjusted various settings like activation functions and regularization methods to find the best-performing model.
                </p>
                <ul>
                    <li>Data augmentation (zooming, flipping, rotating images) to improve model robustness.</li>
                </ul>
                <h3>Evaluation Metrics</h3>
                <p><strong>Accuracy: </strong> 87.10%</p>
                <p><strong>Precision: </strong>82.77%</p>
                <p><strong>Recall: </strong>83.42%</p>
                <p><strong>F1 Score: </strong>83.09%</p>
            </section>
            <section>
                <h2>Results</h2>
                <p>The ConvNeXtBase model showed strong performance with low overfitting. It effectively classified healthy and diseased plants with high accuracy and balanced precision and recall.</p>
            </section>
            <section>
                <h2>Team Contributions</h2>
                <p>The project was a collaborative effort where each member contributed to different aspects, ensuring a smooth workflow and leveraging each person's strengths.</p>
                <ul>
                    <li>Matteo Miano</li>
                    <li>Luca Brembilla</li>
                    <li>Linda Frickleton</li>
                    <li>Marianna Dragonetti</li>
                </ul>
            </section>
        </div>
    );
};

export default PlantClassification;
