import React, { useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import './Header.css';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faGithub, faInstagram, faLinkedinIn, faXTwitter } from "@fortawesome/free-brands-svg-icons";
import { faBars, faTimes } from '@fortawesome/free-solid-svg-icons';

const Header = ({ activeSection }) => {
  const [isOpen, setIsOpen] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();

  // Check if the current page is the home page
  const isHomePage = location.pathname === '/';
  // Only apply the active state if on the home page
  const currentActive = isHomePage ? activeSection : '';

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  // Define the scrollToSection function
  const scrollToSection = (sectionId) => (e) => {
    e.preventDefault();

    if (!isHomePage) {
      // Navigate to home first, then scroll after a short delay
      navigate('/');
      setTimeout(() => {
        const element = document.getElementById(sectionId);
        if (element) {
          element.scrollIntoView({ behavior: 'smooth' });
        }
      }, 300);
    } else {
      // Already on home, just scroll
      const element = document.getElementById(sectionId);
      if (element) {
        element.scrollIntoView({ behavior: 'smooth' });
      }
    }

    // Close the menu after clicking
    setIsOpen(false);
  };

  return (
    <header className="header">
      <div className="left-header">
        <div className="logo">Bre</div>
        <div className="social-icons">
          <a
            href="https://www.linkedin.com/in/lucabrembilla/"
            target="_blank"
            rel="noopener noreferrer"
            className="social-icon"
          >
            <FontAwesomeIcon icon={faLinkedinIn} />
          </a>
          <a
            href="https://www.instagram.com/il_breee"
            target="_blank"
            rel="noopener noreferrer"
            className="social-icon"
          >
            <FontAwesomeIcon icon={faInstagram} />
          </a>
          <a
            href="https://github.com/LucaBrembilla"
            target="_blank"
            rel="noopener noreferrer"
            className="social-icon"
          >
            <FontAwesomeIcon icon={faGithub} />
          </a>
          <a
            href="https://x.com/LucaBrembillaa"
            target="_blank"
            rel="noopener noreferrer"
            className="social-icon"
          >
            <FontAwesomeIcon icon={faXTwitter} />
          </a>
        </div>
      </div>
      <div className="menu-toggle" onClick={toggleMenu}>
        <FontAwesomeIcon icon={isOpen ? faTimes : faBars} />
      </div>
      <nav className={isOpen ? 'open' : ''}>
        <ul>
          <li className={currentActive === 'home' ? 'active' : ''}>
            <a href="/" onClick={scrollToSection('home')}>Home</a>
          </li>
          <li className={currentActive === 'about' ? 'active' : ''}>
            <a href="/" onClick={scrollToSection('about')}>About</a>
          </li>
          <li className={currentActive === 'computerscience' ? 'active' : ''}>
            <a href="/" onClick={scrollToSection('computerscience')}>Computer Science</a>
          </li>
          <li className={currentActive === 'contact' ? 'active' : ''}>
            <a href="/" onClick={scrollToSection('contact')}>Contact</a>
          </li>
        </ul>
      </nav>
    </header>
  );
};

export default Header;
