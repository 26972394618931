import React, { useState } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Header from './components/Header';
import Footer from './components/Footer';
import HomePage from './HomePage';
import PlantClassification from './projects/PlantClassification';
import './App.css';

const App = () => {
  const [activeSection, setActiveSection] = useState('home');

  return (
    <Router>
      <Header activeSection={activeSection} />
      <Routes>

        {/* Home page with scroll logic */}
        <Route
          path="/"
          element={<HomePage activeSection={activeSection} setActiveSection={setActiveSection} />}
        />

        {/* Project detail page */}
        <Route 
          path="/projects/plant-classification" 
          element={<PlantClassification/>} 
        />
      </Routes>
      <Footer />
    </Router>
  );
};

export default App;

